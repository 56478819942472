import { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import NavBarWrapper from 'components/Nav/NavBarWrapper';
const LazyStoryPointingPage = lazy(
  async () => await import('./components/StoryPointingPage/StoryPointingPage'),
);
const LazyRoomSelectorPage = lazy(
  async () => await import('./components/LandingPage/LandingPage'),
);

export const router = createBrowserRouter([
  {
    element: <NavBarWrapper />,
    children: [
      {
        path: '/',
        element: <LazyRoomSelectorPage />,
      },
      {
        path: '/rooms/:room_id',
        element: <LazyStoryPointingPage />,
      },
    ],
  },
]);
