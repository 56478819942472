import {
  Box,
  useColorModeValue,
  HStack,
  Code,
  Grid,
  Tooltip,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from 'ColorModeSwitcher';
import { useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { type StoryPointPageParams } from 'types/RouterParams.type';
import { CopyIcon } from '@chakra-ui/icons';

const NavBar = () => {
  const { room_id } = useParams<StoryPointPageParams>();
  const [showCopySuccess, setShowCopySuccess] = useState(false);

  return (
    <Box
      bg={useColorModeValue('gray.100', 'gray.900')}
      // px={{ base: '1rem', sm: '2rem' }}
      py="0.5rem"
    >
      <div className="d-flex justify-content-between px-3 px-md-4">
        <HStack>
          <NavLink to="/">{room_id ? 'Lobby' : 'Story Pointing'}</NavLink>
        </HStack>
        <HStack justifyContent={'center'}>
          <Box>
            {room_id ? (
              <Tooltip
                label={showCopySuccess ? 'Copied!' : 'Copy Room Link'}
                closeDelay={500}
                onClose={() => setShowCopySuccess(false)}
              >
                <Code
                  className="cursor-pointer"
                  onClick={() => {
                    const url = window.location.href;
                    navigator.clipboard.writeText(url);
                    console.log(url);
                    setShowCopySuccess(true);
                  }}
                >
                  <div>
                    {room_id} <CopyIcon />
                  </div>
                </Code>
              </Tooltip>
            ) : null}
          </Box>
        </HStack>
        <HStack justifyContent={'flex-end'}>
          <ColorModeSwitcher />
        </HStack>
      </div>
    </Box>
  );
};

export default NavBar;
