import {
  createApi,
  fetchBaseQuery,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { type StoryPointRoom } from 'types/StoryPointingRoom.type';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_PUBLIC_URL,
});

export type CustomErrorData = {
  error: string;
};

const baseQueryWithError: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  return await baseQuery(args, api, extraOptions);
};

interface SubmitPointRequest {
  room_id: string;
  name: string;
  point: number | null;
}

interface RenameRequest {
  room_id: string;
  old_name: string | null;
  new_name: string;
}

export const api = createApi({
  baseQuery: baseQueryWithError,
  tagTypes: ['StoryPoints'],
  endpoints: (builder) => ({
    createRoom: builder.mutation<{ room_id: string }, void>({
      query: () => ({
        url: 'create_room',
        method: 'POST',
      }),
    }),
    pollPoint: builder.query<StoryPointRoom, string>({
      query: (room_id: string) => ({
        url: 'poll_point',
        method: 'GET',
        params: { room_id },
      }),
      providesTags: ['StoryPoints'],
    }),
    submitPoint: builder.mutation<void, SubmitPointRequest>({
      query: (body: SubmitPointRequest) => ({
        url: 'submit_point',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['StoryPoints'],
    }),
    resetEstimates: builder.mutation<void, string>({
      query: (room_id: string) => ({
        url: 'reset_estimates',
        method: 'DELETE',
        body: {
          room_id,
        },
      }),
      invalidatesTags: ['StoryPoints'],
    }),
    rename: builder.mutation<void, RenameRequest>({
      query: (body: RenameRequest) => ({
        url: 'rename',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['StoryPoints'],
    }),
    resetRoom: builder.mutation<void, string>({
      query: (room_id: string) => ({
        url: 'reset_room',
        method: 'DELETE',
        body: {
          room_id,
        },
      }),
      invalidatesTags: ['StoryPoints'],
    }),
  }),
});

export const {
  useCreateRoomMutation,
  usePollPointQuery,
  useSubmitPointMutation,
  useResetEstimatesMutation,
  useRenameMutation,
  useResetRoomMutation,
} = api;
