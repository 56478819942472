// categories
export const CategoryInteraction = 'Interaction';
export const CategoryWebVitals = 'WebVitals';
// actions
export const ActionSubmitPoint = 'SubmitPoint';
export const ActionRename = 'Rename';
export const ActionCreateRoom = 'CreateRoom';
export const ActionJoinRoom = 'JoinRoom';
export const ActionResetEstimates = 'ResetEstimates';
export const ActionResetRoom = 'ResetRoom';
