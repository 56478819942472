import { ColorModeScript, ChakraProvider, theme } from '@chakra-ui/react';
import { Suspense, StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { store } from './store';
import { RouterProvider } from 'react-router-dom';
import { router } from './router';
import ReactGA from 'react-ga4';
import { CategoryWebVitals } from 'constants/GAEvents';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/global.css';

const container = document.getElementById('root');
if (container == null) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(container);

const GA_TRACKING_ID = 'G-K20678EEBW';
ReactGA.initialize(GA_TRACKING_ID);

root.render(
  <StrictMode>
    <ColorModeScript />
    <ChakraProvider theme={theme}>
      <Provider store={store}>
        <Suspense fallback="Loading...">
          <RouterProvider router={router} />
        </Suspense>
      </Provider>
    </ChakraProvider>
  </StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals((metric) => {
  console.log(metric);
  ReactGA.event({
    category: CategoryWebVitals,
    action: metric.name,
    value: metric.value,
    label: metric.id,
    nonInteraction: true,
  });
});
