import CoffeeIcon from 'bmc-button.svg';
import { chakra } from '@chakra-ui/react';

const Coffee = () => (
  <chakra.a target="_blank" href="https://www.buymeacoffee.com/james10424">
    <chakra.img src={CoffeeIcon} width="10rem" />
  </chakra.a>
);

export default Coffee;
