import NavBar from './NavBar';
import { Outlet } from 'react-router-dom';
import { Center, VStack, chakra, Spacer } from '@chakra-ui/react';
import Footer from './Footer';

const NavBarWrapper = () => (
  <VStack align="stretch" spacing={5} height="100vh">
    <NavBar />

    <Spacer />

    <Center>
      <Outlet />
    </Center>

    <Spacer />

    <Footer />
  </VStack>
);

export default NavBarWrapper;
