import {
  Box,
  Container,
  Stack,
  Flex,
  Text,
  HStack,
  useColorModeValue,
  SimpleGrid,
} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import Coffee from './Coffee';

const Footer = () => (
  <Box
    bg={useColorModeValue('gray.50', 'gray.900')}
    color={useColorModeValue('gray.700', 'gray.200')}
  >
    <div
      className="d-flex flex-column flex-md-row justify-content-between align-items-center py-3 px-4 gap-2"
      // as={SimpleGrid}
      // py="0.5rem"
      // maxW="6xl"
      // columns={{ base: 1, md: 3 }}
      // direction={{ base: 'column', md: 'row' }}
      // justify={{ base: 'center', md: 'space-between' }}
      // spacing={3}
      // align="center"
      // alignItems="center"
    >
      <Flex justifyContent={{ base: 'center', md: 'flex-start' }}>
        <NavLink to="/">Story Pointing</NavLink>
      </Flex>
      <Text justifyContent="center">Made by James with ❤️</Text>
      <Flex justifyContent={{ base: 'center', md: 'flex-end' }}>
        <Coffee />
      </Flex>
    </div>
  </Box>
);

export default Footer;
